export default {
  data() {
    return {
      barList: [{
        name: "個人資料",
        toName: "personal"
      }, {
        name: "我的行程編輯",
        toName: "schedule"
      }, {
        name: "我的景點編輯",
        toName: "personalPoint"
      }, {
        name: "我的簡介編輯",
        toName: "personalIntroduction"
      }, {
        name: "我的景點照片",
        toName: "personalPhoto"
      }, {
        name: "我的景點照片",
        toName: "personalPhoto"
      }],
    }
  }

}
