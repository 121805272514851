<template>
  <div>
    <div class="d-flex flex-wrap align-items-center justify-content-around">
      <router-link style="width: 150px; height: 80px" v-for="(btn, index) in barList" :key="index"
        class="d-flex align-items-center justify-content-center rounded border bg-dagee my-3"
        :to="{name: btn.toName}">
        <b class="font-up-4 text-dark">{{btn.name}}</b>
      </router-link>
    </div>
    <!-- <div class="w-100 d-flex">
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!4v1601274388289!6m8!1m7!1sd8x6r4MN-_DLB-5VOPuAow!2m2!1d25.02491563026813!2d121.5434541767146!3f219.35968761360817!4f-4.9124646385287605!5f0.7820865974627469"
          width="300" height="200" frameborder="0" style="border:0;" allowfullscreen=""
          aria-hidden="false" tabindex="0"></iframe>

        <iframe
          src="https://www.google.com/maps/embed?pb=!6m8!1m7!1svv1-aafzzZmS4pImGuxWLQ!2m2!1d!2d!3f!4f!5"
          width="300" height="220" frameborder="0" style="border:0;" allowfullscreen=""
          aria-hidden="false" tabindex="0"></iframe>
      </div>
    </div> -->
  </div>
</template>

<script>
  import ctrl from "./Index.js"
  export default ctrl;

</script>
